import React from "react"

const TransactionInProgress = ({ liNumber, className }) => {
  return (
    <div className={`tip ${className}`}>
      <h1>{liNumber}</h1>
      <h2>Transaction In Process</h2>
      <p>
        Detecting currency and location of the
        <br />
        customer
      </p>
    </div>
  )
}

export default TransactionInProgress
