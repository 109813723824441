export const HeroTableData = [
  {
    method: "https://i.ibb.co/LryNgM0/a1.png",
    amount: "£68",
    status: "captured",
  },
  {
    method: "https://i.ibb.co/s3Bn381/a2.png",
    amount: "£41",
    status: "captured",
  },
  {
    method: "https://i.ibb.co/HG97pYy/a4.png",
    amount: "£128",
    status: "captured",
  },
  {
    method: "https://i.ibb.co/xzKGjSX/a5.png",
    amount: "£18",
    status: "expired",
  },
  {
    method: "https://i.ibb.co/xzKGjSX/a5.png",
    amount: "£347",
    status: "captured",
  },
  {
    method: "https://i.ibb.co/QNbVsqZ/a5.png",
    amount: "£101",
    status: "in progress",
  },
  {
    method: "https://i.ibb.co/3pqDFdY/a6.png",
    amount: "£38",
    status: "in progress",
  },
]

export const FeaturesSectionData = [
  {
    image: "https://via.placeholder.com/150x150.png?text=Shopping+Cart",
    heading: "Global eCommerce",
    description:
      "One stop shop for the full stack of your payments requirements.",
  },

  {
    image: "https://via.placeholder.com/150x150.png?text=Shopping+Cart",
    heading: "Bespoke Consulting",
    description:
      "We run an industry leading consultancy service specialising in negotiating rates on your behalf.",
  },

  {
    image: "https://via.placeholder.com/150x150.png?text=Shopping+Cart",
    heading: "Intelligent Routing",
    description:
      "APEXX optimises your payments flow by routing transactions based on key criteria to any number oacquirers in order to improve sales and reduce costs",
  },

  {
    image: "https://via.placeholder.com/150x150.png?text=Shopping+Cart",
    heading: "Unlimited APM’s",
    description:
      "APEXX provides the right mix of payment methods to help your business grow quickly globally.",
  },
]

export const CountriesData = [
  {
    amount: "£206.36",
    cityName: "London, UK",
    countryCode: "gb",
    img: "https://fintek.pl/wp-content/uploads/2018/08/Visa9.png",
    alt: "Visa"
  },
  {
    amount: "$34",
    cityName: "California, US",
    countryCode: "us",
    img:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/200px-Mastercard-logo.svg.png",
      alt: "Mastercard"
  },
  {
    amount: "¥318",
    cityName: "Shanghai, China",
    countryCode: "cn",
    img: "https://i.ibb.co/QY62G5X/Union-Pay-logo.png",
    alt: "Union Pay"
  },
  {
    amount: "₽8,109",
    cityName: "Moscow, Russia",
    countryCode: "ru",
    img: "https://i.ibb.co/qB3z05f/mir-logo-small-en.png",
    alt: "MIR"
  },
]
