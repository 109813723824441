import React from "react"

import { StyledGlobalIntegrationSection } from "./GlobalIntegrationSection.styles"
import { Container, Card, Row, Col } from "react-bootstrap"

import HouseIcon from "./HouseIcon"
import TopRight from "./TopRight"
import Logo from "../../Global/Layout/Logo/Logo"
import BubbleGumButton from "./BubbleGumButton"
import BigGreenCheckAnimated from "./BigGreenCheckAnimated"
import DownChevron from "./DownChevron"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Divider from "./Divider"
import { motion, useTransform } from "framer-motion"
import CurvedLine from "./CurvedLine"
import TransactionInProgress from "./TransactionInProgress"
import { useInView } from "react-intersection-observer"
import Switch from "./Switch"
import { CountriesData } from "../../../data/homePage.data"

import loadable from "@loadable/component"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"

const TextTransition = loadable(() => import("react-text-transition"))
const ReactCountryFlag = loadable(() => import("react-country-flag"))

export const base_animation_trigger = .27

const GlobalIntegrationSection = ({ scrollYProgress, index }) => {
  const [ref, inView] = useInView({ rootMargin: "300px 0px -350px 0px" })
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "grouped_logos" } }
        sort: { fields: absolutePath, order: ASC }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const { amount, cityName, countryCode, img, alt } = CountriesData[
    index % CountriesData.length
  ]

  const images = data.allFile.edges
  return (
    <StyledGlobalIntegrationSection className="pt-5 pb-5 poppins d-none d-lg-block">
      <Container>
        <h2 className="h1 ambit font-weight-bold text-center mb-5 mt-5">Payment Orchestration Platform</h2>
        <div className="text-center">
          <DownChevron className="chevron mb-5" />
          <div className="d-flex align-items-center" style={{ position: 'relative', zIndex: '1' }}>
            <HouseIcon className="house-icon" />
          </div>
        </div>
        <div className="text-center mt-5">
          <p className="pt-2">Merchant</p>
        </div>

        <Divider
          height={650}
          range={[base_animation_trigger, base_animation_trigger+.15]}
          scrollYProgress={scrollYProgress}
        />

        <div
          className="d-flex justify-content-end top-right w-75"
          style={{ height: "150px" }}
        >
          <motion.div
            style={{
              scale: useTransform(scrollYProgress, [base_animation_trigger, base_animation_trigger+.05], [0, 1]),
            }}
          >
            <TopRight className="tr-image" />
            <TransactionInProgress liNumber={"01"} />
          </motion.div>
        </div>
        <motion.div
          className="text-center"
          style={{
            scale: useTransform(scrollYProgress, [base_animation_trigger+.05, base_animation_trigger+.07], [0, 1]),
          }}
        >
          <Card className="mx-auto">
            <Card.Header className="p-4 d-flex justify-content-between">
              <span>
                <TextTransition text={amount} />
              </span>

              <img src={img} className="img-fluid" alt={alt} />
            </Card.Header>
            <Card.Footer className="d-flex justify-content-between p-4 align-items-center text-white">
              <span>Detected Location:</span>
              <span className="d-flex align-items-center">
                <TextTransition className="font-weight-bold" text={cityName} />
                &nbsp;
                <ReactCountryFlag
                  countryCode={countryCode}
                  svg
                  className="rounded-circle ml-2"
                />
              </span>
            </Card.Footer>
          </Card>
        </motion.div>

        <motion.div
          className="d-flex top-right align-content-center p-5"
          style={{
            scale: useTransform(scrollYProgress, [base_animation_trigger+.1, base_animation_trigger+.12], [0, 1]),
          }}
        >
          <TransactionInProgress liNumber={"02"} className="tip-2" />
          <div className="pound d-flex align-items-center">
            <span className="rounded-circle text-center poppins">&pound;</span>
          </div>
        </motion.div>
        <div className="d-flex justify-content-center pt-5 pb-0 ">
          <div
            className="check-box d-flex justify-content-center rounded-circle align-content-center"
            ref={ref}
          >
            <Switch checked={inView} />
          </div>
        </div>

        <div className="d-flex justify-content-center transaction-3 align-items-center">
          <motion.div
            className="pr-2"
            style={{
              scale: useTransform(scrollYProgress, [base_animation_trigger+.18, base_animation_trigger+.2], [0, 1]),
              marginBottom: "28px",
            }}
          >
            <Logo />
            <h4>Orchestration Platform</h4>
          </motion.div>

          <Divider
            height={150}
            range={[base_animation_trigger+0.18, base_animation_trigger+0.2]}
            scrollYProgress={scrollYProgress}
          />
          <motion.div
            className="pl-5"
            style={{
              scale: useTransform(scrollYProgress, [base_animation_trigger+.18, base_animation_trigger+.2], [0, 1]),
            }}
          >
            <TransactionInProgress liNumber={"03"} />
          </motion.div>
        </div>

        <Row style={{ minHeight: "400px" }}>
          <Col className="d-flex flex-column mt-5">
            <CurvedLine
              scrollYProgress={scrollYProgress}
              className="curved-line-1 mt-1"
            />
            <motion.div
              className="bubble-gum-brand text-center w-50 mx-auto"
              style={{
                scale: useTransform(scrollYProgress, [base_animation_trigger+.25, base_animation_trigger+.26], [0, 1]),
              }}
            >
              <BubbleGumButton text="United States" />
              <Img
                alt="JP Morgan, First Data, PayPal"
                className="w-75 mx-auto"
                fluid={images[0].node.childImageSharp.fluid}
              />
            </motion.div>
            <motion.div
              className="bubble-gum-brand text-center w-50 mx-auto"
              style={{
                scale: useTransform(scrollYProgress, [base_animation_trigger+.25, base_animation_trigger+.26], [0, 1]),
              }}
            >
              <BubbleGumButton
                fill="rgba(56, 93, 167, 0.6)"
                text="Latin America"
              />
              <Img
              alt="7Eleven, Cielo, Oxxo, Banamex"
                className="w-75 mx-auto"
                fluid={images[1].node.childImageSharp.fluid}
              />
            </motion.div>
          </Col>

          <Divider
            height={490}
            scrollYProgress={scrollYProgress}
            range={[ base_animation_trigger +0.26,base_animation_trigger +.3]}
            style={{ marginTop: "-42px" }}
            hideBall={false}
          />

          <Col className="d-flex flex-column align-items-center mt-5">
            <motion.div
              className="bubble-gum-brand text-center w-50"
              style={{
                scale: useTransform(scrollYProgress, [base_animation_trigger+.25, base_animation_trigger+.26], [0, 1]),
              }}
            >
              <BubbleGumButton fill="rgba(30, 127, 194, 0.6)" text="Europe" />
              <Img alt="Barclaycard, Elavon" fluid={images[2].node.childImageSharp.fluid} />
            </motion.div>

            <motion.div
              className="bubble-gum-brand text-center w-50"
              style={{
                scale: useTransform(scrollYProgress, [base_animation_trigger+.25, base_animation_trigger+.26], [0, 1]),
              }}
            >
              <BubbleGumButton text="Middle East" />
              <Img alt="Mashreq"
                className="w-75 mx-auto"
                fluid={images[3].node.childImageSharp.fluid}
              />
            </motion.div>
          </Col>

          <Col className="d-flex flex-column align-items-center mt-5">
            <motion.div
              className="bubble-gum-brand text-center w-50"
              style={{
                scale: useTransform(scrollYProgress, [base_animation_trigger+.25, base_animation_trigger+.26], [0, 1]),
              }}
            >
              <BubbleGumButton fill="rgba(56, 93, 167, 0.6)" text="India" />
              <Img alt="ICICI Bank, PayTM, RuPay"
                className="mx-auto"
                fluid={images[4].node.childImageSharp.fluid}
              />
            </motion.div>

            <motion.div
              className="bubble-gum-brand text-center w-50 mt-5"
              style={{
                scale: useTransform(scrollYProgress, [base_animation_trigger+.25, base_animation_trigger+.26], [0, 1]),
              }}
            >
              <BubbleGumButton fill="rgba(30, 127, 194, 0.6)" text="China" />
              <Img alt="Payeco, WeChat Pay, Alipay, UnionPay"
                className="mx-auto"
                fluid={images[5].node.childImageSharp.fluid}
              />
            </motion.div>
          </Col>
          <Col className="d-flex flex-column align-items-center mt-5">
            <CurvedLine
              scrollYProgress={scrollYProgress}
              className="curved-line-2"
            />

            <motion.div
              className="bubble-gum-brand text-center w-50"
              style={{
                scale: useTransform(scrollYProgress, [base_animation_trigger+.25, base_animation_trigger+.26], [0, 1]),
              }}
            >
              <BubbleGumButton
                scrollYProgress={scrollYProgress}
                text="Russia"
              />
              <Img alt="Alpha Bank, Sberbank, VTB, MNP"
                className=" mx-auto"
                fluid={images[6].node.childImageSharp.fluid}
              />
            </motion.div>

            <motion.div
              className="bubble-gum-brand text-center w-50"
              style={{
                scale: useTransform(scrollYProgress, [base_animation_trigger+.25, base_animation_trigger+.26], [0, 1]),
              }}
            >
              <BubbleGumButton
                fill="rgba(56, 93, 167, 0.6)"
                scrollYProgress={scrollYProgress}
                text="Australia"
              />
              <Img alt="First Data, After Pay, Poli"
                className="w-75 mx-auto"
                fluid={images[7].node.childImageSharp.fluid}
              />
            </motion.div>
          </Col>
        </Row>

        <div className="text-center mt-3" style={{position: "relative",zIndex: "1"}}>
          <BigGreenCheckAnimated
            className="green-check"
            scrollYProgress={scrollYProgress}
          />
          <motion.h5
            className="mt-4 mb-5"
            style={{
              opacity: useTransform(scrollYProgress, [base_animation_trigger+.3, base_animation_trigger+.32], [0, 1]),
              y: useTransform(scrollYProgress, [base_animation_trigger+.3, base_animation_trigger+.32], [-30, 0]),
              transition: {
                ease: "linear",
              },
            }}
          >
            Payment Accepted
          </motion.h5>
        </div>
      </Container>
    </StyledGlobalIntegrationSection>
  )
}

export default GlobalIntegrationSection
